import React, { useContext, useMemo } from 'react';

export type TFunction = (...agrs: any) => string;
export type IUseTranslationResp = TFunction[] & { t: TFunction };

export const TranslationContext = React.createContext<TFunction>((v) => v);

// Supports both:
// [t] = useTranslation()
// {t} = useTranslation()
export const useTranslation = () => {
  const t = useContext(TranslationContext);
  return useMemo(() => {
    const resp: IUseTranslationResp = [t] as IUseTranslationResp;
    resp.t = t;
    return resp;
  }, [t]);
};
