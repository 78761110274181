import { useBILogger } from '@wix/fe-essentials/react';
import { useCallback } from 'react';

const EVENT_ID = {
  ADDON_INSTALL_TRY: 105,
  ADDON_INSTALL_SUCCESS: 103,
  ADDON_UNINSTALL_TRY: 106,
  ADDON_UNINSTALL_SUCCESS: 107,
};

export interface AddonInstallEventPayload {
  origin: 'addon_market_card';
  host_app_id: string;
}

export interface AddonUninstallEventPayload {
  origin: 'addon_market_installed_addons_list' | 'addon_market_card';
  host_app_id: string;
}

export const useBi = () => {
  const logger = useBILogger();
  const addonInstallTry = useCallback(
    (payload: AddonInstallEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.ADDON_INSTALL_TRY,
      });
    },
    [logger],
  );
  const addonInstallSuccess = useCallback(
    (payload: AddonInstallEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.ADDON_INSTALL_SUCCESS,
      });
    },
    [logger],
  );
  const addonUninstallTry = useCallback(
    (payload: AddonUninstallEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.ADDON_UNINSTALL_TRY,
      });
    },
    [logger],
  );
  const addonUninstallSuccess = useCallback(
    (payload: AddonUninstallEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.ADDON_UNINSTALL_SUCCESS,
      });
    },
    [logger],
  );
  return {
    addonInstallTry,
    addonInstallSuccess,
    addonUninstallTry,
    addonUninstallSuccess,
  };
};
