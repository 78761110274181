import React from 'react';

export const SvgErrorState = () => (
  <svg width={120} height={120} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.1 55.71 25.55 79.4l-7.52-19.63-.66 20.67-10.4-11.52 3.01 28.07h34.83L32.1 55.71Z"
      fill="#192C55"
    />
    <path
      d="m73.56 96.54 2-4A18.58 18.58 0 0 1 92 82.06a16.92 16.92 0 0 1 10.69 3.77l13.25 10.71"
      fill="#28BF9A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m67.54 39.69-5.57-16.68h-7.41l-5.6 16.68h18.58Zm11.12 33.35L73.1 56.36H43.36l-5.59 16.68h40.89Z"
      fill="#FCA760"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m73.1 56.36-5.56-16.67H48.96l-5.6 16.67H73.1Zm11.127 33.349L78.66 73.04H37.77l-5.604 16.669L26 89.71l-.24 6.82h65.1l.23-6.82-6.863-.001Z"
      fill="#FFD527"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.18 90.08v-1H56.05v1h28.13ZM119 97.04v-1H1v1h118Z"
      fill="#0E264C"
    />
  </svg>
);
