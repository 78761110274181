import React, { useMemo } from 'react';
import { PlatformWorkerManager } from '@wix/editor-platform-worker';

export interface IPlatformFrameContext {
  getWorkerManager: () => Promise<PlatformWorkerManager>;
}

export const PlatformFrameContext =
  React.createContext<IPlatformFrameContext | null>(null);

export const PlatformFrameContextProvider: React.FC<
  IPlatformFrameContext & React.PropsWithChildren
> = ({ getWorkerManager, children }) => {
  const value = useMemo<IPlatformFrameContext>(
    () => ({
      getWorkerManager,
    }),
    [getWorkerManager],
  );

  return (
    <PlatformFrameContext.Provider value={value}>
      {children}
    </PlatformFrameContext.Provider>
  );
};
