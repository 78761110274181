import React, { useMemo, useEffect } from 'react';
import { CompRef } from '@wix/document-services-types';
import { create, FedopsLogger } from '@wix/fedops-logger';
import { EditorIntegrationAPI, WidgetSlot } from '../../types/widgetPlugins';

function sortWidgetSlots(widgetSlots: WidgetSlot[]): WidgetSlot[] {
  const getSlotRank = (slot: WidgetSlot): number =>
    slot.pluginInfo?.widgetId ? 0 : 1;

  // empty slots should be first
  return [...widgetSlots].sort(
    (slot1, slot2) => getSlotRank(slot2) - getSlotRank(slot1),
  );
}

export interface IWidgetDataContextInput {
  editorIntegrationAPI: EditorIntegrationAPI;
  widgetRef?: CompRef;
  children?: React.ReactNode;
}

export interface IWidgetDataContextValue {
  editorIntegrationAPI: EditorIntegrationAPI;
  widgetRef?: CompRef;
  widgetSlots: WidgetSlot[];
  fedopsLogger: FedopsLogger;
}

export function calcWidgetDataContextValue(
  editorIntegrationAPI: EditorIntegrationAPI,
  fedopsLogger: FedopsLogger,
  widgetRef?: CompRef,
): IWidgetDataContextValue {
  const widgetSlots = widgetRef
    ? sortWidgetSlots(editorIntegrationAPI.getWidgetSlots(widgetRef))
    : [];

  return {
    editorIntegrationAPI,
    widgetRef,
    widgetSlots,
    fedopsLogger,
  };
}

export const WidgetDataContext = React.createContext<IWidgetDataContextValue>(
  {} as IWidgetDataContextValue,
);

export const WidgetDataProvider: React.FC<IWidgetDataContextInput> = ({
  editorIntegrationAPI,
  widgetRef,
  children,
}) => {
  const fedopsLogger = useMemo(
    () => create('editor-platform-host-integration'),
    [],
  );
  useEffect(() => {
    fedopsLogger.appLoadStarted();
  }, [fedopsLogger]);

  const widgetData = useMemo(
    () =>
      calcWidgetDataContextValue(editorIntegrationAPI, fedopsLogger, widgetRef),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorIntegrationAPI, widgetRef?.id, fedopsLogger],
  );

  return (
    <WidgetDataContext.Provider value={widgetData}>
      {children}
    </WidgetDataContext.Provider>
  );
};
