import { BILoggerProvider } from '@wix/fe-essentials/react';
import React, { FC, ReactNode, useMemo } from 'react';
import { createBiLogger } from '../../essentials/bi';
import {
  getEditorInfo,
  getEditorSessionId,
  getMetaSiteId,
  getUserId,
} from '../../utils';

interface Props {
  children?: ReactNode;
}

/**
 * Use hook
 * ```
 * import { useBILogger } from '@wix/fe-essentials/react';
 * ```
 * in order to access the logger instance.
 */
export const EditorPlatformBIProvider: FC<Props> = ({ children }) => {
  const userId = getUserId() || 'EMPTY_USER_ID';
  const metaSiteId = getMetaSiteId();
  const editorSessionId = getEditorSessionId() || '';
  const editorInfo = getEditorInfo();

  const logger = useMemo(() => {
    return createBiLogger({
      userId,
      editorSessionId,
      metaSiteId,
      editorType: editorInfo?.host?.toLowerCase() || 'EMPTY_EDITOR_TYPE',
    });
  }, [userId, editorSessionId, metaSiteId, editorInfo?.host]);
  return <BILoggerProvider value={logger}>{children}</BILoggerProvider>;
};
