import React from 'react';
import { Box, BoxProps } from '@wix/design-system';

export interface ContainerProps extends BoxProps {
  alignContent?: boolean;
}

export const Container: React.FC<ContainerProps> = (props) => {
  const { alignContent, ...boxProps } = props;
  const alignProps = alignContent
    ? ({
        verticalAlign: 'middle',
        align: 'center',
      } as Pick<BoxProps, 'align' | 'verticalAlign'>)
    : {};

  return (
    <Box
      {...boxProps}
      {...alignProps}
      padding="SP5"
      direction="vertical"
      backgroundColor="#F1F4F7"
    >
      {props.children}
    </Box>
  );
};
