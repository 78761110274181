import React from 'react';

export const SvgEmptyState = () => (
  <svg width={120} height={120} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m29.26 21.19 11.96 3.21-6.928 23.179H95.06L93.4 100.33h-64l1.279-40.662L18.42 100.69 6.46 97.48l22.8-76.29Zm41.27 38.38H54.89a3.81 3.81 0 0 0-3.77 3.65 3.51 3.51 0 0 0 3.52 3.65h15.63a3.81 3.81 0 0 0 3.78-3.65 3.51 3.51 0 0 0-3.52-3.65Z"
      fill="#C2E2FF"
    />
    <path d="M74.27 89.92H49.11l.49-14.01h25.15l-.48 14.01Z" fill="#fff" />
    <path
      d="M88.26 61.91a3.92 3.92 0 0 1 2.95 1.3c.271.293.488.631.64 1 .12.25.205.516.25.79l1.858 6.112a6.445 6.445 0 0 1 4.512-2.107L98.7 69a5.939 5.939 0 0 1 6 6.2l-.48 13.6H91.81l.47-13.56a6.434 6.434 0 0 1 .899-3.075L86.85 69.93l-.63-.22a3.382 3.382 0 0 1-1-.71A3.85 3.85 0 0 1 84 66a4.25 4.25 0 0 1 4.26-4.09Z"
      fill="#28BF9A"
    />
    <path
      d="M119.46 99.68v1H.08v-1h119.38Zm-54.7-15.47v1h-5.78v-1h5.78Zm37.625-4 3.492 1.573-.411.912-3.492-1.574.411-.912Zm-11.379-.921 3.292 1.939-.508.862-3.291-1.94.508-.861ZM67.22 80.62v1H56.77v-1h10.45Zm30.38-7.378 1.823 3.358-.88.477-1.821-3.358.879-.477Zm5.746-3.845.943.333-1.304 3.696-.943-.332 1.304-3.697Zm-20.044-5.328 3.66 1.165-.304.952L83 65.022l.303-.953ZM34.671 47.6l.949.314-4.202 12.661-.95-.315 4.203-12.66ZM75.41 21.5a13.31 13.31 0 0 1-1.28 14.77 6 6 0 0 1-3.98 2.367l-.28.033c-.14.32-.3.64-.46 1A4.06 4.06 0 0 1 66.8 42a3.2 3.2 0 0 1-3-1.17A8.51 8.51 0 0 1 62.28 43c-.352.403-.79.72-1.28.93-.26.096-.535.143-.81.14a3.3 3.3 0 0 1-2.61-1.53 7.92 7.92 0 0 1-1-3.38l1-.12a6.999 6.999 0 0 0 .81 3 2.06 2.06 0 0 0 2.23 1c.349-.157.66-.389.91-.68a7.79 7.79 0 0 0 1.55-2.4c-.17-.28-.321-.57-.45-.87a11.659 11.659 0 0 1-1-4.7 1.62 1.62 0 0 1 .37-1.1 1.12 1.12 0 0 1 .77-.38 1.4 1.4 0 0 1 1 .33c.364.349.609.804.7 1.3a9.06 9.06 0 0 1-.26 5.23A2.5 2.5 0 0 0 66.59 41c.71-.14 1.34-.76 1.94-1.88.11-.21.21-.42.31-.64l-.28-.11c-1.58-.68-2.51-2.58-2.47-5.07.021-1.708.34-3.4.94-5a2.13 2.13 0 0 1 1-1.3c.37-.175.799-.175 1.17 0a3.06 3.06 0 0 1 1.42 2 14.87 14.87 0 0 1-.35 8.66 5.39 5.39 0 0 0 3.09-2A12.43 12.43 0 0 0 74.54 22l.87-.5ZM62.962 33.908l-.083.002a.53.53 0 0 0-.16.44c-.01 1.45.28 2.887.85 4.22a8.05 8.05 0 0 0 0-3.78 1.51 1.51 0 0 0-.38-.79.41.41 0 0 0-.227-.092Zm5.647-6.038v.04h-.16a1.28 1.28 0 0 0-.48.74 13.567 13.567 0 0 0-.88 4.67c0 .78.09 3.38 1.86 4.14l.28.1a13.82 13.82 0 0 0 .42-8.34 2.12 2.12 0 0 0-.86-1.3.39.39 0 0 0-.18-.05Z"
      fill="#192C55"
    />
    <path d="M106.75 100.04H88.49L89 85.41h18.26l-.51 14.63Z" fill="#116DFF" />
  </svg>
);
