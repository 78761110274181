import { useContext, useMemo } from 'react';
import { PluginsContext } from '../pluginsContext';

export function useInstalledPlugins() {
  const { plugins } = useContext(PluginsContext);

  return useMemo(() => {
    return plugins.filter(
      (plugin) => plugin.slot?.pluginInfo?.widgetId === plugin.widgetRefId,
    );
  }, [plugins]);
}
