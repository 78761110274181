import React from 'react';
import Experiments from '@wix/wix-experiments';
import { PureExperimentsProvider } from '@wix/wix-experiments-react';

function lazy<T>(initFunc: () => T) {
  let value: T;
  return () => {
    if (!value) {
      value = initFunc();
    }

    return value;
  };
}

const experimentsLazy = lazy(
  () =>
    new Experiments({
      scope: 'widget-plugins',
    }),
);

export const ExperimentsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <PureExperimentsProvider experiments={experimentsLazy()}>
      {children}
    </PureExperimentsProvider>
  );
};
