import React from 'react';
import { IWidgetsData, useWidgetPlugins } from './hooks';

export const PluginsContext = React.createContext<IWidgetsData>({
  plugins: [],
  isLoading: true,
  error: undefined,
  fetchWidgetPlugins: () => {},
  resetPluginInstallationStateBySlot: () => {},
  setPanelError: () => {},
});

export const PluginsProvider: React.FC<{ children?: React.ReactNode }> = (
  props,
) => {
  const widgetPluginsData = useWidgetPlugins();

  return (
    <PluginsContext.Provider value={widgetPluginsData}>
      {props.children}
    </PluginsContext.Provider>
  );
};
