import React from 'react';

export const SvgFallbackLogo = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0"
        y="0"
        width="36"
        height="36"
        rx="6"
        fill="#F7F7F7"
        stroke="#DFE5EB"
      />
    </svg>
  );
};
