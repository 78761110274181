import React from 'react';

import type { WidgetPlugin } from '../../../types/widgetPlugins';
import { SvgFallbackLogo } from '../../shared/panel/svg/svgFallbackLogo';

import s from './pluginsPanel.scss';

interface PluginLogoProps {
  plugin: WidgetPlugin;
}

export const PluginLogo: React.FC<PluginLogoProps> = ({ plugin }) => {
  return plugin.logoUrl ? (
    <img
      alt={`${plugin.name} icon`}
      src={plugin.logoUrl}
      className={s.pluginIcon}
    />
  ) : (
    <SvgFallbackLogo />
  );
};
