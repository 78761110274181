import React, { VFC } from 'react';
import { Box, EmptyState as WSREmptyState } from '@wix/design-system';

interface EmptyStateProps {
  title: string;
  subtitle: string;
  image: JSX.Element;
  dataHook: string;
}

export const EmptyState: VFC<EmptyStateProps> = ({
  title,
  subtitle,
  image,
  dataHook,
}) => (
  <Box dataHook={dataHook}>
    <WSREmptyState title={title} subtitle={subtitle} image={image} />
  </Box>
);
