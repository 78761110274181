import React from 'react';
import {
  Box,
  Card,
  IconButton,
  PopoverMenu,
  ListItemSelect,
  Layout,
} from '@wix/design-system';
import { More, Delete, Visible, ChangeOrder } from '@wix/wix-ui-icons-common';

import { PluginLogo } from './pluginLogo';
import { useTranslation } from '../../useTranslation';
import { useInstalledPlugins, useWidgetPluginManager } from '../hooks';
import { WidgetPlugin, WidgetSlot } from '../../../types/widgetPlugins';

import s from './pluginsPanel.scss';

const slotHighlightRevertMs = 2000;

export interface InstalledPluginProps {
  plugin: WidgetPlugin;
}

export const InstalledPluginPopoverMenu: React.FC<InstalledPluginProps> = ({
  plugin,
}) => {
  const {
    isPluginMoveActionEnabled,
    highlightSlot,
    uninstallPlugin,
    movePlugin,
  } = useWidgetPluginManager();
  const [t] = useTranslation();

  const showMovePluginAction = isPluginMoveActionEnabled(plugin.slot);

  return (
    <Box>
      <PopoverMenu
        appendTo="scrollParent"
        textSize="small"
        placement="left"
        dataHook="widgetPlugins.installedPlugins.moreActions"
        triggerElement={({ toggle, open, close }) => (
          <IconButton
            skin="inverted"
            onClick={toggle}
            onMouseEnter={open}
            onMouseLeave={close}
          >
            <More />
          </IconButton>
        )}
      >
        {showMovePluginAction && (
          <PopoverMenu.MenuItem
            text={t('PLATFORM_Widget_Slots_Move_Plugin')}
            prefixIcon={<ChangeOrder />}
            onClick={() => movePlugin(plugin.slot as WidgetSlot)}
            dataHook="widgetPlugins.installedPlugins.action.movePlugin"
          />
        )}
        <PopoverMenu.MenuItem
          text={t('PLATFORM_Widget_Slots_Remove_Plugin')}
          prefixIcon={<Delete />}
          onClick={() => uninstallPlugin(plugin.slot as WidgetSlot)}
          dataHook="widgetPlugins.installedPlugins.action.delete"
        />
        <PopoverMenu.MenuItem
          text={t('PLATFORM_Widget_Slots_Show_Plugin_On_Page')}
          prefixIcon={<Visible />}
          onClick={() => highlightSlot(plugin.slot, slotHighlightRevertMs)}
          dataHook="widgetPlugins.installedPlugins.action.showOnPage"
        />
      </PopoverMenu>
    </Box>
  );
};

export const InstalledPluginListItem: React.FC<InstalledPluginProps> = ({
  plugin,
}) => {
  const { highlightSlot } = useWidgetPluginManager();

  return (
    <ListItemSelect
      size="medium"
      className={s.installedPluginListItem}
      onClick={() => highlightSlot(plugin.slot, slotHighlightRevertMs)}
      prefix={
        <Box>
          <PluginLogo plugin={plugin} />
        </Box>
      }
      title={plugin.name}
      dataHook={`widgetPlugins.installedPlugins.pluginId:${plugin.id}`}
      suffix={<InstalledPluginPopoverMenu plugin={plugin} />}
    />
  );
};

export const InstalledPlugins: React.FC = () => {
  const installedPlugins = useInstalledPlugins();
  const [t] = useTranslation();

  if (!installedPlugins.length) {
    return null;
  }

  return (
    <Card className={s.installedPluginsBlock}>
      <Card.Header title={t('PLATFORM_Widget_Slots_Installed_Plugins')} />
      <Card.Divider />
      <Layout cols={1} gap={0}>
        {installedPlugins.map((plugin) => (
          <InstalledPluginListItem plugin={plugin} key={plugin.id} />
        ))}
      </Layout>
    </Card>
  );
};
