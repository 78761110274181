import React from 'react';
import { Box, Button, EmptyState } from '@wix/design-system';

export const ErrorState: React.FC<{
  title: string;
  subtitle: string;
  image: JSX.Element;
  tryAgain: () => void;
  cta: string;
}> = ({ tryAgain, title, subtitle, image, cta }) => {
  return (
    <Box align="center" verticalAlign="middle" direction="vertical">
      <EmptyState title={title} subtitle={subtitle} image={image} />
      <Box direction="vertical" display="block" marginTop="SP4">
        <Button onClick={tryAgain}>{cta}</Button>
      </Box>
    </Box>
  );
};
