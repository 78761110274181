import { DeleteSmall, ExternalLinkSmall, More } from '@wix/wix-ui-icons-common';
import React, { Fragment } from 'react';
import {
  Box,
  Divider,
  IconButton,
  Image,
  PopoverMenu,
  Text,
} from '@wix/design-system';
import { useTranslation } from '../../useTranslation';
import styles from './installedAddonsList.scss';
import { useAddons } from './useAddons';
import { SvgFallbackLogo } from '../../shared/panel/svg/svgFallbackLogo';

export const InstalledAddonsList = () => {
  const { getInstalledAddons, openAddonPanel, uninstallAddon } = useAddons();
  const [t] = useTranslation();
  const installedAddons = getInstalledAddons();
  if (installedAddons.length === 0) {
    return null;
  }

  return (
    <Box marginBottom="SP5" direction="vertical" className={styles.root}>
      <Box
        direction="vertical"
        background="#fff"
        paddingTop="SP2"
        paddingBottom="SP2"
        paddingLeft="SP3"
        paddingRight="SP3"
        borderTopLeftRadius={6}
        borderTopRightRadius={6}
        border="1px solid #dfe5eb"
        borderBottom="none"
      >
        <Text size="small" weight="bold" as="h3">
          {t('PLATFORM_addons_panel_Installed_title')}
        </Text>
      </Box>
      <Divider />
      <Box
        direction="vertical"
        background="#fff"
        borderBottomLeftRadius={6}
        borderBottomRightRadius={6}
        border="1px solid #dfe5eb"
        borderTop="none"
      >
        {installedAddons.map((addon, index) => {
          const logo = addon.iconUrl ? (
            <Image
              alt={`${addon.name} addon icon`}
              src={addon.iconUrl}
              borderRadius={6}
              width={30}
              height={30}
              showBorder
              transparent
            />
          ) : (
            <SvgFallbackLogo />
          );
          return (
            <Fragment key={addon.appDefinitionId}>
              <Box
                paddingTop="SP2"
                paddingBottom="SP2"
                paddingLeft="SP3"
                paddingRight="SP3"
                className={styles.installedAddon}
              >
                <Box marginRight="SP2">{logo}</Box>
                <Box>
                  <Text size="small">{addon.name}</Text>
                </Box>
                <Box flexGrow={1} />
                <Box>
                  <PopoverMenu
                    appendTo="scrollParent"
                    textSize="small"
                    placement="right"
                    showArrow={false}
                    moveBy={{ x: 18, y: 0 }}
                    triggerElement={({ toggle }) => (
                      <IconButton
                        priority="secondary"
                        skin="standard"
                        size="small"
                        onClick={toggle}
                      >
                        <More />
                      </IconButton>
                    )}
                  >
                    <PopoverMenu.MenuItem
                      prefixIcon={<ExternalLinkSmall />}
                      text={t(
                        'PLATFORM_addons_panel_Installed_actions_open_label',
                      )}
                      onClick={() => {
                        openAddonPanel(addon.appDefinitionId);
                      }}
                    />
                    <PopoverMenu.MenuItem
                      prefixIcon={<DeleteSmall />}
                      text={t(
                        'PLATFORM_addons_panel_Installed_actions_remove_label',
                      )}
                      onClick={() => {
                        uninstallAddon(addon.appDefinitionId, {
                          origin: 'addon_market_installed_addons_list',
                        });
                      }}
                    />
                  </PopoverMenu>
                </Box>
              </Box>
              {index + 1 < installedAddons.length && <Divider />}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
