import { AddonsMarketProps } from '@wix/editor-platform-host-integration-apis';
import React, { FC } from 'react';
import {
  Container,
  ContainerProps,
  ErrorState,
  LoadingState,
  SvgErrorState,
} from '../../shared/panel';
import { WixDesignSystemProvider } from '@wix/design-system';
import { TranslationContext, useTranslation } from '../../useTranslation';
import { AddonsContextProvider } from './addonsContext';
import styles from './addonsMarket.scss';
import { Grid } from './grid';
import { useAddons } from './useAddons';
import { InstalledAddonsList } from './installedAddonsList';
import { EditorPlatformBIProvider } from '../../providers/EditorPlatformBIProvider';

import '@wix/design-system/styles.global.css';

interface AddonsMarketOwnProps {
  height: string;
  width: string;
}

const getContainerProps = (props: ContainerProps = {}): ContainerProps => {
  return {
    ...props,
    dataHook: 'addons.addonsMarket',
    className: styles.root,
  };
};

const AddonsMarketPure: FC<AddonsMarketOwnProps> = ({ height, width }) => {
  const [t] = useTranslation();
  const { state, fetchAddons } = useAddons();

  if (state === 'idle' || state === 'loading') {
    return (
      <Container {...getContainerProps({ height, width, alignContent: true })}>
        <LoadingState dataHook="addonsMarket.loading" />
      </Container>
    );
  }

  if (state === 'error') {
    // TODO: use different keys for network errors and server errors
    return (
      <Container {...getContainerProps({ height, width, alignContent: true })}>
        <ErrorState
          title={t('PLATFORM_addons_panel_error_server_title')}
          subtitle={t('PLATFORM_addons_panel_error_server_subtitle')}
          image={<SvgErrorState />}
          cta={t('PLATFORM_addons_panel_error_CTA')}
          tryAgain={fetchAddons}
        />
      </Container>
    );
  }

  return (
    <Container {...getContainerProps({ direction: 'vertical', height, width })}>
      <InstalledAddonsList />
      <Grid />
    </Container>
  );
};

export const AddonsMarket: FC<AddonsMarketProps> = ({
  t,
  openAddonPanel,
  closeAddonPanel,
  handleInstall,
  handleUninstall,
  isInstalled,
}) => {
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <TranslationContext.Provider value={t}>
        <EditorPlatformBIProvider>
          <AddonsContextProvider
            openAddonPanel={openAddonPanel}
            closeAddonPanel={closeAddonPanel}
            handleInstall={handleInstall}
            handleUninstall={handleUninstall}
            isInstalled={isInstalled}
          >
            <AddonsMarketPure height="546" width="792" />
          </AddonsContextProvider>
        </EditorPlatformBIProvider>
      </TranslationContext.Provider>
    </WixDesignSystemProvider>
  );
};
