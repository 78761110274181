import { useBILogger } from '@wix/fe-essentials/react';
import { useCallback } from 'react';

const EVENT_ID = {
  PLUGIN_INSTALL_TRY: 105,
  PLUGIN_INSTALL_SUCCESS: 103,
  PLUGIN_UNINSTALL_TRY: 106,
  PLUGIN_UNINSTALL_SUCCESS: 107,
};

type TEventId = (typeof EVENT_ID)[keyof typeof EVENT_ID];

interface PluginEventPayload {
  slot_id: string;
  plugin_id: string;
}

export const usePluginsBi = () => {
  const logger = useBILogger();

  const pluginInstallTry = useCallback(
    (payload: PluginEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.PLUGIN_INSTALL_TRY,
      });
    },
    [logger],
  );

  const pluginInstallSuccess = useCallback(
    (payload: PluginEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.PLUGIN_INSTALL_SUCCESS,
      });
    },
    [logger],
  );

  const pluginUninstallTry = useCallback(
    (payload: PluginEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.PLUGIN_UNINSTALL_TRY,
      });
    },
    [logger],
  );

  const pluginUninstallSuccess = useCallback(
    (payload: PluginEventPayload) => {
      logger.log({
        ...payload,
        evid: EVENT_ID.PLUGIN_UNINSTALL_SUCCESS,
      });
    },
    [logger],
  );

  return {
    pluginInstallTry,
    pluginInstallSuccess,
    pluginUninstallTry,
    pluginUninstallSuccess,
  };
};
