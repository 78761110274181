import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react';
import { HttpError } from '@wix/http-client';
import type { PluginPlacement } from '@wix/ambassador-devcenter-appsruntimereader-v1-app-runtime-data/types';
import { find } from 'lodash';

import { WidgetPlugin, WidgetSlot } from '../../../types/widgetPlugins';
import { getWidgetPlugins } from '../../../apis/utils/widgetPluginsService';
import { WidgetDataContext } from '../widgetDataContext';

function findWidgetSlotByPlacements(
  widgetSlots: WidgetSlot[],
  widgetPlugin: WidgetPlugin,
) {
  const matchingSlots = widgetSlots.filter((slot) =>
    find(widgetPlugin.placements, slot.placement),
  );

  return (
    matchingSlots.find(
      (slot) => slot?.pluginInfo?.widgetId === widgetPlugin.widgetRefId,
    ) ?? matchingSlots[0]
  );
}

function getWidgetSlotsPlacements(
  widgetSlots: WidgetSlot[],
): PluginPlacement[] {
  return Array.from(new Set(widgetSlots.map((slot) => slot.placement)));
}

function getIncludeAppsList(widgetSlots: WidgetSlot[]) {
  const includeApps = widgetSlots
    .map((slot) => slot.pluginInfo?.appDefinitionId)
    .filter(Boolean) as string[];

  const appsToIncludeQueryString = new URL(
    window.location.href,
  ).searchParams.get('include-widget-plugin-apps');

  if (appsToIncludeQueryString) {
    includeApps.push(...appsToIncludeQueryString.split(','));
  }
  return includeApps;
}

export type PanelError = HttpError | Error;

export interface IWidgetsData {
  plugins: WidgetPlugin[];
  isLoading: boolean;
  error: PanelError | undefined;
  fetchWidgetPlugins: () => void;
  resetPluginInstallationStateBySlot: (slot: WidgetSlot) => void;
  setPanelError: Dispatch<SetStateAction<PanelError | undefined>>;
}

export const useWidgetPlugins = (): IWidgetsData => {
  const [plugins, setPlugins] = useState<WidgetPlugin[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setPanelError] = useState<PanelError | undefined>();
  const { widgetSlots, fedopsLogger } = useContext(WidgetDataContext);

  const fetchWidgetPlugins = useCallback(async () => {
    fedopsLogger.interactionStarted('plugins-loading-interaction');
    setLoading(true);
    const params = {
      includeApps: getIncludeAppsList(widgetSlots),
      placements: getWidgetSlotsPlacements(widgetSlots),
    };
    try {
      const widgetPlugins = (await getWidgetPlugins(params)).map((plugin) => ({
        ...plugin,
        slot: findWidgetSlotByPlacements(widgetSlots, plugin),
      }));

      setPlugins(widgetPlugins);
      setPanelError(undefined);
      fedopsLogger.interactionEnded('plugins-loading-interaction');
    } catch (err) {
      setPlugins([]);
      setPanelError(err as HttpError);
    } finally {
      setLoading(false);
    }
  }, [widgetSlots, fedopsLogger]);

  useEffect(() => {
    void fetchWidgetPlugins();
  }, [fetchWidgetPlugins]);

  const resetPluginInstallationStateBySlot = useCallback(
    (slot: WidgetSlot) => {
      const updatedPlugins = plugins.map((plugin) =>
        plugin?.slot?.compRef.id === slot.compRef.id
          ? { ...plugin, slot: { ...plugin.slot, pluginInfo: undefined } }
          : plugin,
      );

      setPlugins(updatedPlugins);
    },
    [plugins],
  );

  // useMemo in this case allows to provide the whole object as-is
  return useMemo(
    () => ({
      plugins,
      isLoading,
      error,
      fetchWidgetPlugins,
      resetPluginInstallationStateBySlot,
      setPanelError,
    }),
    [
      plugins,
      isLoading,
      error,
      fetchWidgetPlugins,
      resetPluginInstallationStateBySlot,
      setPanelError,
    ],
  );
};
