(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["EditorPlatformHostIntegration"] = factory(require("react"), require("lodash"), require("react-dom"));
	else
		root["EditorPlatformHostIntegration"] = factory(root["React"], root["_"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__5329__, __WEBPACK_EXTERNAL_MODULE__484__, __WEBPACK_EXTERNAL_MODULE__5561__) => {
return 