import React, { useMemo, useCallback, useState } from 'react';

type PluginsUpdateModeContextData = {
  isUpdateInProgress: boolean;
  startUpdateMode: () => void;
  stopUpdateMode: () => void;
};

export const PluginsUpdateModeContext =
  React.createContext<PluginsUpdateModeContextData>({
    isUpdateInProgress: false,
    startUpdateMode: () => {},
    stopUpdateMode: () => {},
  });

export const PluginsUpdateModeProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const startUpdateMode = useCallback(() => {
    setIsUpdateInProgress(true);
  }, [setIsUpdateInProgress]);

  const stopUpdateMode = useCallback(() => {
    setIsUpdateInProgress(false);
  }, [setIsUpdateInProgress]);

  const context = useMemo(
    () => ({
      isUpdateInProgress,
      startUpdateMode,
      stopUpdateMode,
    }),
    [isUpdateInProgress, startUpdateMode, stopUpdateMode],
  );

  return (
    <PluginsUpdateModeContext.Provider value={context}>
      {props.children}
    </PluginsUpdateModeContext.Provider>
  );
};
