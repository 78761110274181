import { useContext, useMemo } from 'react';
import { WidgetPlugin, WidgetSlot } from '../../../types/widgetPlugins';
import { WidgetDataContext } from '../widgetDataContext';
import { useTranslation } from '../../useTranslation';
import { PluginsContext } from '../pluginsContext';
import { usePluginsUpdateMode } from './usePluginsUpdateMode';
import { useInstalledPlugins } from './useInstalledPlugins';
import { usePluginsBi } from './useBi';

export type WidgetSlotOrPlugin = WidgetSlot | WidgetPlugin | undefined;

export function useWidgetPluginManager() {
  const { editorIntegrationAPI, widgetRef, fedopsLogger } =
    useContext(WidgetDataContext);
  const { resetPluginInstallationStateBySlot, setPanelError } =
    useContext(PluginsContext);
  const { startUpdateMode, stopUpdateMode } = usePluginsUpdateMode();
  const installedPlugins = useInstalledPlugins();
  const bi = usePluginsBi();

  const [t] = useTranslation();

  return useMemo(() => {
    function getSlot(slotOrPlugin: WidgetSlotOrPlugin): WidgetSlot | undefined {
      if ((slotOrPlugin as WidgetSlot)?.compRef) {
        return slotOrPlugin as WidgetSlot;
      }
      return (slotOrPlugin as WidgetPlugin)?.slot;
    }

    function isPluginInstalled(widgetPlugin: WidgetPlugin) {
      return (
        widgetPlugin.slot?.pluginInfo?.widgetId === widgetPlugin.widgetRefId
      );
    }

    function isSlotPopulatedInstalled(slotOrPlugin: WidgetSlotOrPlugin) {
      return !!getSlot(slotOrPlugin)?.pluginInfo;
    }

    async function installOrReplacePlugin(
      widgetPlugin: WidgetPlugin,
      slot = widgetPlugin.slot,
    ) {
      if (!slot) {
        return;
      }
      try {
        startUpdateMode();

        if (slot.pluginInfo) {
          if (await confirmPluginReplace(widgetPlugin, slot)) {
            fedopsLogger.interactionStarted('plugin-replacement-interaction');

            await _uninstallPlugin(slot);
            await installPlugin(widgetPlugin, slot);

            fedopsLogger.interactionEnded('plugin-replacement-interaction');
          }
        } else {
          fedopsLogger.interactionStarted('plugin-installation-interaction');

          await installPlugin(widgetPlugin, slot);

          fedopsLogger.interactionEnded('plugin-installation-interaction');
        }
      } finally {
        stopUpdateMode();
      }
    }

    async function installPlugin(
      widgetPlugin: WidgetPlugin,
      slot = widgetPlugin.slot,
    ) {
      if (!slot) {
        return;
      }

      bi.pluginInstallTry({
        slot_id: slot.compRef.id,
        plugin_id: widgetPlugin.appDefinitionId,
      });

      try {
        await editorIntegrationAPI.installWidgetPlugin(slot.compRef, {
          appDefinitionId: widgetPlugin.appDefinitionId,
          widgetId: widgetPlugin.widgetRefId,
        });
      } catch (e: any) {
        setPanelError(e);
        throw new Error(e.message);
      }

      bi.pluginInstallSuccess({
        slot_id: slot.compRef.id,
        plugin_id: widgetPlugin.appDefinitionId,
      });

      editorIntegrationAPI.closeWidgetPluginsPanel();
      editorIntegrationAPI.unhighlightSlot(slot.compRef);
    }

    async function uninstallPlugin(slot: WidgetSlot) {
      try {
        startUpdateMode();

        bi.pluginUninstallTry({
          slot_id: slot.compRef.id,
          plugin_id: slot.pluginInfo?.appDefinitionId!,
        });

        if (await confirmPluginUninstall(slot)) {
          fedopsLogger.interactionStarted('plugin-uninstallation-interaction');

          await _uninstallPlugin(slot);

          bi.pluginUninstallSuccess({
            slot_id: slot.compRef.id,
            plugin_id: slot.pluginInfo?.appDefinitionId!,
          });

          resetPluginInstallationStateBySlot(slot);
          editorIntegrationAPI.unhighlightSlot(slot.compRef);
          fedopsLogger.interactionEnded('plugin-uninstallation-interaction');

          // uninstalling last plugin
          if (installedPlugins.length === 1) {
            editorIntegrationAPI.closeWidgetPluginsPanel();
          }
        }
      } finally {
        stopUpdateMode();
      }
    }

    async function _uninstallPlugin(slot: WidgetSlot) {
      try {
        await editorIntegrationAPI.uninstallWidgetPlugin(slot.compRef);
      } catch (e: any) {
        setPanelError(e);
        throw new Error(e.message);
      }
    }

    async function confirmPluginUninstall(slotOrPlugin: WidgetSlotOrPlugin) {
      const slot = getSlot(slotOrPlugin);
      if (!slot) {
        return;
      }
      return new Promise((resolve) => {
        editorIntegrationAPI.openPanel(
          'tpaPanels.confirmation.pluginDeleteOrReplace',
          {
            replace: false,
            pluginName:
              slot.pluginInfo?.name ??
              t('PLATFORM_Widget_Slots_default_plugin_name'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
          },
          true,
        );
      });
    }

    async function confirmPluginReplace(
      widgetPlugin: WidgetPlugin,
      slot = widgetPlugin.slot,
    ) {
      if (!slot) {
        return;
      }
      return new Promise((resolve) => {
        editorIntegrationAPI.openPanel(
          'tpaPanels.confirmation.pluginDeleteOrReplace',
          {
            replace: true,
            pluginName:
              widgetPlugin.name ??
              t('PLATFORM_Widget_Slots_default_new_plugin_name'),
            oldPluginName:
              slot.pluginInfo?.name ??
              t('PLATFORM_Widget_Slots_default_old_plugin_name'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
          },
          true,
        );
      });
    }

    async function highlightSlot(slot?: WidgetSlot, unhighlightInMs = 0) {
      if (!slot) {
        return;
      }

      if (editorIntegrationAPI.isBlocks()) {
        return;
      }

      await editorIntegrationAPI.highlightSlot(slot.compRef);

      if (unhighlightInMs) {
        setTimeout(() => unhighlightSlot(slot), unhighlightInMs);
      }
    }

    async function unhighlightSlot(slot?: WidgetSlot) {
      if (!slot) {
        return;
      }

      if (editorIntegrationAPI.isBlocks()) {
        return;
      }

      editorIntegrationAPI.unhighlightSlot(slot.compRef);
    }

    function isPluginMoveActionEnabled(slot: WidgetSlot | undefined) {
      if (!slot) {
        return false;
      }

      try {
        return editorIntegrationAPI.isWidgetPluginMoveActionEnabled(
          slot.compRef,
        );
      } catch (e) {
        return false;
      }
    }

    function movePlugin(slot: WidgetSlot) {
      editorIntegrationAPI.movePlugin(slot.compRef);
    }

    return {
      widgetRef,
      isSlotPopulatedInstalled,
      isPluginInstalled,
      isPluginMoveActionEnabled,
      installOrReplacePlugin,
      uninstallPlugin,
      highlightSlot,
      unhighlightSlot,
      movePlugin,
    };
  }, [
    widgetRef,
    startUpdateMode,
    stopUpdateMode,
    installedPlugins,
    editorIntegrationAPI,
    resetPluginInstallationStateBySlot,
    t,
    fedopsLogger,
  ]);
}
