// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dhIH5X{margin-top:1em}.kmKw_W{padding:4px 0}.kmKw_W:last-child{border-radius:0 0 8px 8px}.F3NcXf{border-radius:4px}.v7f2dE{border-radius:4px}.xHlRpg{width:35px;height:35px;border-radius:4px;border:solid 1px #dfe5eb}.qPjgyp{padding:18px;border:solid 1px #e3e3e3;border-radius:4px;justify-content:space-between}.qPjgyp .NVCcfo{color:#7a92a5}.F7mFJX{position:absolute;right:16px;top:16px}.iZpYj5{width:24px;height:24px}.A5GYD7{display:flex;align-items:center}.TxmK2y{margin-right:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"installed-plugins-block": `dhIH5X`,
	"installedPluginsBlock": `dhIH5X`,
	"installed-plugin-list-item": `kmKw_W`,
	"installedPluginListItem": `kmKw_W`,
	"plugin-card": `F3NcXf`,
	"pluginCard": `F3NcXf`,
	"plugin-icon-container": `v7f2dE`,
	"pluginIconContainer": `v7f2dE`,
	"plugin-icon": `xHlRpg`,
	"pluginIcon": `xHlRpg`,
	"plugin-details": `qPjgyp`,
	"pluginDetails": `qPjgyp`,
	"plugin-price": `NVCcfo`,
	"pluginPrice": `NVCcfo`,
	"plugin-info-button": `F7mFJX`,
	"pluginInfoButton": `F7mFJX`,
	"plugin-info-icon": `iZpYj5`,
	"pluginInfoIcon": `iZpYj5`,
	"plugin-installed": `A5GYD7`,
	"pluginInstalled": `A5GYD7`,
	"plugin-installed-icon": `TxmK2y`,
	"pluginInstalledIcon": `TxmK2y`
};
export default ___CSS_LOADER_EXPORT___;
