import React, { ReactNode, useCallback, useContext, useEffect } from 'react';
import { HttpError } from '@wix/http-client';

import { PluginsGrid } from './pluginsGrid';
import { TFunction, useTranslation } from '../../useTranslation';
import { PluginsContext } from '../pluginsContext';
import { InstalledPlugins } from './installedPlugins';
import { WidgetDataContext } from '../widgetDataContext';
import { useExperiments } from '@wix/wix-experiments-react';
import {
  Container,
  ErrorState,
  LoadingState,
  EmptyState,
  SvgErrorState,
  SvgNetworkErrorState,
  SvgEmptyState,
} from '../../shared/panel';
import { PanelError } from '../hooks/useWidgetPlugins';

// boxHeight together with padding fits to `PluginSettingsMaxHeight` in santa-editor
// @see https://github.com/wix-private/santa-editor/blob/40fc553f2af3125b21def06c02e6ee75571547de/santa-editor/packages/panels/widgetPanels/pluginsPanel.tsx#L26
const boxHeight = '466';

const PluginsMarketEmptyState: React.FC = () => {
  const [t] = useTranslation();
  return (
    <EmptyState
      title={t('PLATFORM_Widget_Slots_Empty_State_title')}
      subtitle={t('PLATFORM_Widget_Slots_Empty_State_subtitle')}
      image={<SvgEmptyState />}
      dataHook="widgetPlugins.pluginsMarket.empty"
    />
  );
};

function getErrorViewProps({ error, t }: { error: PanelError; t: TFunction }): {
  title: string;
  subtitle: string;
  image: JSX.Element;
} {
  const isNetrowkError = error?.message === 'Network Error';
  if (isNetrowkError) {
    return {
      title: t('PLATFORM_Widget_Slots_Error_Connection_title'),
      subtitle: t('PLATFORM_Widget_Slots_Error_Connection_subtitle'),
      image: <SvgNetworkErrorState />,
    };
  }
  return {
    title: t('PLATFORM_Widget_Slots_Error_Server_title'),
    subtitle: t('PLATFORM_Widget_Slots_Error_Server_subtitle'),
    image: <SvgErrorState />,
  };
}

const PluginsMarkerErrorState: React.FC<{
  tryAgain: () => void;
  error: PanelError;
}> = ({ tryAgain, error }) => {
  const [t] = useTranslation();
  const errorViewProps = getErrorViewProps({ error, t });
  return (
    <ErrorState
      title={errorViewProps.title}
      subtitle={errorViewProps.subtitle}
      image={errorViewProps.image}
      cta={t('PLATFORM_Widget_Slots_Error_CTA')}
      tryAgain={tryAgain}
    />
  );
};

const PluginMarketContainer: React.FC<{
  alignContent?: boolean;
  children?: ReactNode;
}> = (props) => {
  return (
    <Container
      alignContent={props.alignContent}
      minHeight={boxHeight}
      dataHook="widgetPlugins.pluginsMarket"
      children={props.children}
    />
  );
};

export const PluginsMarket: React.FC = () => {
  const { ready: experimentsReady } = useExperiments();
  const { plugins, isLoading, error, fetchWidgetPlugins } =
    useContext(PluginsContext);
  const { fedopsLogger } = useContext(WidgetDataContext);

  useEffect(() => {
    fedopsLogger.appLoaded();
  }, []);

  const getPluginsMarketLoadingState = useCallback(() => {
    if (isLoading || !experimentsReady) {
      return <LoadingState dataHook="widgetPlugins.pluginsMarket.preloader" />;
    }

    if (error) {
      return (
        <PluginsMarkerErrorState tryAgain={fetchWidgetPlugins} error={error} />
      );
    }

    if (plugins.length === 0) {
      return <PluginsMarketEmptyState />;
    }

    return null;
  }, [isLoading, error, plugins, fetchWidgetPlugins, experimentsReady]);

  const pluginMarketLoadingState = getPluginsMarketLoadingState();

  if (pluginMarketLoadingState) {
    return (
      <PluginMarketContainer alignContent>
        {pluginMarketLoadingState}
      </PluginMarketContainer>
    );
  }

  return (
    <PluginMarketContainer>
      <InstalledPlugins />
      <PluginsGrid />
    </PluginMarketContainer>
  );
};
