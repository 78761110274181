import React from 'react';

export const SvgNetworkErrorState = () => (
  <svg width={120} height={120} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.13 73.43.28-8h20.71l-.94 26.77H9.47l.28-8.69a6 6 0 0 1-3 .84 5.72 5.72 0 0 1-5.75-6 6.11 6.11 0 0 1 6-5.85c1.112 0 2.2.323 3.13.93Zm-5.4 7.05a2.7 2.7 0 0 0 2 .83 3.09 3.09 0 0 0 3.02-2.96A2.72 2.72 0 0 0 7 75.5a3.1 3.1 0 0 0-3 2.98h-.04a2.78 2.78 0 0 0 .77 2Z"
      fill="#28BF9A"
    />
    <path d="M111.21 88.12H28.6l6.24-58.32h82.61l-6.24 58.32Z" fill="#116DFF" />
    <path d="m37.63 36.15-5.6 52.1H108.11l5.59-52.1H37.63Z" fill="#C2E2FF" />
    <path
      d="M111.24 88.12H99.42l-6.12 1.63 2 2.45h11.34a4.6 4.6 0 0 0 4.6-4.08Z"
      fill="#192C55"
    />
    <path
      d="M94.73 92.19H17.85a3.64 3.64 0 0 1-3.62-4.07h36.92l6 1.11 5.74-1.11h36.53a4.73 4.73 0 0 1-4.69 4.07Z"
      fill="#116DFF"
    />
    <path
      d="M62.85 88.12a2.15 2.15 0 0 1-2.15 2.15h-7.4a2.15 2.15 0 0 1-2.15-2.15h11.7ZM1.58 92.2h116.83v1H1.58z"
      fill="#192C55"
    />
    <ellipse
      cx={72.876}
      cy={62.193}
      rx={16.15}
      ry={15.06}
      transform="rotate(-44 72.876 62.193)"
      fill="#fff"
    />
    <path d="m70.11 64.86-.01.01v-.01h.01Z" fill="#192C55" />
    <path
      d="m70.11 64.86-.01.01v-.01h.01ZM76.72 58.67l-2.24 2.12h-.02l2.26-2.12Z"
      fill="#192C55"
    />
    <path
      d="m70.11 64.86-.01.01v-.01h.01ZM76.72 58.67l-2.24 2.12h-.02l2.26-2.12Z"
      fill="#192C55"
    />
    <path
      d="m20.89 71.59.21-6.17-1-.03-.21 6.19-2.52.01-.28 7.92h6.13l.28-7.92h-2.61Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.29 42 26 43a11 11 0 0 0-10.47 3.15 5.141 5.141 0 0 0-1.3 2.08 2.24 2.24 0 0 0 .33 2.05c.135.155.298.284.48.38A15.6 15.6 0 0 1 24 47.49c.83 0 1.35.34 1.41.91a1.3 1.3 0 0 1-.62 1.08A12.75 12.75 0 0 1 17 52a6.278 6.278 0 0 1-1.74-.25c-.27.22-.53.45-.79.69a1.92 1.92 0 0 0-.58 1c-.08.5 0 1.05 1.27 1.39a7.819 7.819 0 0 0 3.09.2c.16-.2.34-.4.53-.6a7 7 0 0 1 1.86-1.38 1.07 1.07 0 0 1 1.45.48 1.1 1.1 0 0 1-.48 1.49 9.662 9.662 0 0 1-2.82.94 5.2 5.2 0 0 0-1.05 2.27 2.68 2.68 0 0 0 1.13 2.8l-.51.91a3.7 3.7 0 0 1-1.64-3.84c.12-.708.375-1.387.75-2a8.76 8.76 0 0 1-2.61-.3c-1.9-.52-2.13-1.66-2-2.52a2.79 2.79 0 0 1 .87-1.57l.51-.46a2.566 2.566 0 0 1-.42-.39 3.23 3.23 0 0 1-.53-3c.299-.94.82-1.795 1.52-2.49A12 12 0 0 1 26.29 42Zm55.32 12.11-.69-.76-4.48 4.19a13.93 13.93 0 0 0-12.24 3.08l.64.76a13.1 13.1 0 0 1 10.72-3l-2.4 2.25-.333-.007a10.24 10.24 0 0 0-6.667 2.437l.64.76a9.29 9.29 0 0 1 5.28-2.17l-7.3 6.82.69.73 3.21-3 1.53-1.35 3.38-3.18.94-.88 2.26-2.12.82-.78 4-3.78Zm-8.204 13.823c-.54-.56-1.466-.544-2.065.035-.6.58-.648 1.503-.107 2.063.54.56 1.466.545 2.066-.034.6-.58.647-1.503.106-2.064ZM76.49 66l-.71.7a4.85 4.85 0 0 0-5.12-1l1.39-1.3a5.66 5.66 0 0 1 4.44 1.6Zm2.65-2.8a8.819 8.819 0 0 0-3.53-2.08l-.85.8a7.88 7.88 0 0 1 3.68 2l.7-.72Zm2.6-2.76a11.27 11.27 0 0 0-3.16-2.17l-.79.73a10.42 10.42 0 0 1 3.25 2.16l.7-.72Zm-60.64-6.5h-.05a5.392 5.392 0 0 0-1 .65c.366-.13.723-.284 1.07-.46a.1.1 0 0 0 .05-.15s-.05.02-.07.02v-.06Zm-4.446-3.18a14.61 14.61 0 0 1 7.366-2.27 1.06 1.06 0 0 1 .34 0l-.18.16A11.77 11.77 0 0 1 17 51a5.111 5.111 0 0 1-.474-.029l-.16-.022.288-.188Z"
      fill="#192C55"
    />
  </svg>
);
