import { useCallback, useContext } from 'react';
import { AddonsContext } from './addonsContext';
import { useBi } from './useBi';
import type {
  AddonInstallEventPayload,
  AddonUninstallEventPayload,
} from './useBi';

export const useAddons = () => {
  const context = useContext(AddonsContext);
  const bi = useBi();

  if (!context) {
    throw new Error(
      `useAddons() hook must be used inside AddonsContext.Provider component.`,
    );
  }
  const {
    state,
    isInstallationInProgress,
    openAddonPanel,
    closeAddonPanel,
    isInstalled,
    fetchAddons,
    handleInstall,
    handleUninstall,
  } = context;

  const installAddon = useCallback(
    async (
      appDefinitionId: string,
      biData: { origin: AddonInstallEventPayload['origin'] },
    ) => {
      bi.addonInstallTry({ ...biData, host_app_id: appDefinitionId });
      await handleInstall(appDefinitionId);
      bi.addonInstallSuccess({ ...biData, host_app_id: appDefinitionId });
      await openAddonPanel(appDefinitionId);
    },
    [bi, openAddonPanel, handleInstall],
  );

  const uninstallAddon = useCallback(
    async (
      appDefinitionId: string,
      biData: { origin: AddonUninstallEventPayload['origin'] },
    ) => {
      bi.addonUninstallTry({ ...biData, host_app_id: appDefinitionId });
      await handleUninstall(appDefinitionId);
      bi.addonUninstallSuccess({ ...biData, host_app_id: appDefinitionId });
      await closeAddonPanel(appDefinitionId);
    },
    [bi, closeAddonPanel, handleUninstall],
  );

  const getInstalledAddons = useCallback(() => {
    return state.addons.filter(({ appDefinitionId }) =>
      isInstalled(appDefinitionId),
    );
  }, [state.addons, isInstalled]);

  return {
    ...state,
    isInstallationInProgress,
    openAddonPanel,
    getInstalledAddons,
    fetchAddons,
    installAddon,
    uninstallAddon,
  };
};
