import React, { useCallback, useMemo, useState } from 'react';
import { Box, MediaOverlay, Button, Loader } from '@wix/design-system';
import { WidgetPlugin } from '../../../types/widgetPlugins';
import { PluginInfo } from './pluginInfo';
import s from './pluginsPanel.scss';

import { AddBold } from '@wix/wix-ui-icons-common/classic-editor';
import { DeleteSmall, ReplaceSmall } from '@wix/wix-ui-icons-common';
import { useWidgetPluginManager } from '../hooks';
import { useTranslation } from '../../useTranslation';
import { usePluginsUpdateMode } from '../hooks/usePluginsUpdateMode';

export const PluginCard = (props: { plugin: WidgetPlugin }) => {
  const { plugin } = props;

  const { installOrReplacePlugin, uninstallPlugin } = useWidgetPluginManager();
  const [loading, setLoading] = useState(false);

  const handlePluginInstall = useCallback(async () => {
    setLoading(true);
    await installOrReplacePlugin(plugin);
    setLoading(false);
  }, [installOrReplacePlugin, plugin]);

  const handlePluginUninstall = useCallback(async () => {
    setLoading(true);
    await uninstallPlugin(plugin.slot!);
    setLoading(false);
  }, [uninstallPlugin, plugin]);
  const { highlightSlot, unhighlightSlot } = useWidgetPluginManager();

  return (
    <div
      onMouseEnter={() => highlightSlot(plugin.slot)}
      onMouseLeave={() => unhighlightSlot(plugin.slot)}
    >
      <MediaOverlay
        media={<PluginInfo plugin={plugin} />}
        hoverSkin="dark"
        skin={loading ? 'dark' : 'none'}
        className={s.pluginCard}
      >
        <MediaOverlay.Content visible={loading ? 'always' : 'hover'}>
          <Box
            direction="vertical"
            align="center"
            dataHook={`widgetPlugins.pluginId:${plugin.id}`}
          >
            <Box marginBottom="SP2">
              <PluginCardAction
                plugin={plugin}
                loading={loading}
                handleInstall={handlePluginInstall}
                handleUninstall={handlePluginUninstall}
              />
            </Box>
          </Box>
        </MediaOverlay.Content>
      </MediaOverlay>
    </div>
  );
};

export interface IPluginCardActionProps {
  plugin: WidgetPlugin;
  loading: boolean;
  handleInstall: () => void;
  handleUninstall: () => void;
}

export const PluginCardAction: React.FC<IPluginCardActionProps> = ({
  plugin,
  loading,
  handleInstall,
  handleUninstall,
}) => {
  const { isSlotPopulatedInstalled, isPluginInstalled } =
    useWidgetPluginManager();
  const [t] = useTranslation();
  const { isUpdateInProgress } = usePluginsUpdateMode();

  return useMemo(() => {
    const [buttonIcon, buttonText, handler, dataHookAction, disabled] = loading
      ? [undefined, <Loader size="tiny" />, () => {}, undefined, false]
      : isPluginInstalled(plugin)
      ? [
          <DeleteSmall />,
          t('PLATFORM_Widget_Slots_CTA_On_Hover4'),
          handleUninstall,
          'delete',
          isUpdateInProgress,
        ]
      : isSlotPopulatedInstalled(plugin)
      ? [
          <ReplaceSmall />,
          t('PLATFORM_Widget_Slots_CTA_On_Hover5'),
          handleInstall,
          'replace',
          isUpdateInProgress,
        ]
      : [
          <AddBold />,
          t('PLATFORM_Widget_Slots_CTA_On_Hover1'),
          handleInstall,
          'install',
          isUpdateInProgress,
        ];

    return (
      <Button
        onClick={handler}
        skin="inverted"
        prefixIcon={buttonIcon}
        dataHook={`widgetPlugins.action.${dataHookAction}`}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    );
  }, [
    loading,
    handleInstall,
    handleUninstall,
    isPluginInstalled,
    isSlotPopulatedInstalled,
    plugin,
    t,
    isUpdateInProgress,
  ]);
};
