// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KEZ0mY{border:1px solid var(--wsr-color-D60, #dfe5eb);border-radius:6px}.wHeATH{box-sizing:border-box}.wHeATH .JX29tL{display:flex;align-items:center}.wHeATH .JX29tL svg{margin-right:5px}.wHeATH .Byt7o3{color:var(--wsr-color-D30, #595d70)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `KEZ0mY`,
	"root": `wHeATH`,
	"label-installed": `JX29tL`,
	"labelInstalled": `JX29tL`,
	"label-price": `Byt7o3`,
	"labelPrice": `Byt7o3`
};
export default ___CSS_LOADER_EXPORT___;
