import React, { useContext } from 'react';
import { Layout, Cell, Box } from '@wix/design-system';
import { PluginCard } from './pluginCard';

import { PluginsContext } from '../pluginsContext';

export const PluginsGrid = () => {
  const { plugins } = useContext(PluginsContext);
  return (
    <>
      <Box direction="vertical" marginBottom="SP3" marginTop="SP3"></Box>
      <Layout gap="24px" cols={12}>
        {plugins.map((plugin) => (
          <Cell span={4} rows={1} key={plugin.id}>
            <PluginCard plugin={plugin} />
          </Cell>
        ))}
      </Layout>
      <Box direction="vertical" marginTop="SP5"></Box>
    </>
  );
};
