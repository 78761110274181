import React, { useMemo } from 'react';
import {
  IWidgetDataContextInput,
  WidgetDataProvider,
} from './widgetDataContext';
import { PluginsMarket } from './components/pluginsMarket';
import { PluginsProvider } from './pluginsContext';
import { PluginsUpdateModeProvider } from './pluginsUpdateModeContext';
import { ExperimentsProvider } from './experimentsContext';
import { EditorPlatformBIProvider } from '../providers/EditorPlatformBIProvider';

export const WidgetPluginsManager: React.FC<IWidgetDataContextInput> = ({
  editorIntegrationAPI,
  widgetRef,
}) => {
  return useMemo(
    () => (
      <ExperimentsProvider>
        <EditorPlatformBIProvider>
          <WidgetDataProvider
            editorIntegrationAPI={editorIntegrationAPI}
            widgetRef={widgetRef}
          >
            <PluginsProvider>
              <PluginsUpdateModeProvider>
                <PluginsMarket />
              </PluginsUpdateModeProvider>
            </PluginsProvider>
          </WidgetDataProvider>
        </EditorPlatformBIProvider>
      </ExperimentsProvider>
    ),
    // editorIntegrationAPI should not be added to dependencies as it may come directly from non-cached mapStateToProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widgetRef],
  );
};
