import React, { VFC } from 'react';
import { Box, Cell, Heading, Layout } from '@wix/design-system';
import { useTranslation } from '../../useTranslation';
import { GridItem } from './gridItem';
import { useAddons } from './useAddons';

export const Grid: VFC = () => {
  const { addons } = useAddons();
  const [t] = useTranslation();
  return (
    <>
      <Box marginBottom="SP5">
        <Heading size="large">
          {t('PLATFORM_addons_panel_market_title')}
        </Heading>
      </Box>
      <Layout gap="24px" cols={12}>
        {addons.map((addon) => (
          <Cell span={4} rows={1} key={addon.appDefinitionId}>
            <GridItem addon={addon} />
          </Cell>
        ))}
      </Layout>
    </>
  );
};
