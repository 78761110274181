import { HttpClient } from '@wix/http-client';
import type { PluginPlacement } from '@wix/ambassador-devcenter-appsruntimereader-v1-app-runtime-data/types';

import { WidgetPlugin } from '../../types/widgetPlugins';

const httpClient = new HttpClient({
  baseURL: '/_serverless/editor-platform-widget-plugins/',
});

export interface GetWidgetPluginsProps {
  includeApps?: string[];
  interfaces?: string[];
  placements?: PluginPlacement[];
}

export async function getWidgetPlugins(
  params: GetWidgetPluginsProps,
): Promise<WidgetPlugin[]> {
  if (params.placements?.length === 0) {
    return [];
  }

  const queryParams = new URLSearchParams();

  if (params.includeApps?.length) {
    queryParams.append('includeApps', params.includeApps.join(','));
  }

  if (params.placements?.length) {
    queryParams.append('placements', JSON.stringify(params.placements));
  }
  const res = await httpClient.get<WidgetPlugin[]>(
    `/plugins?${queryParams.toString()}`,
  );

  return res.data;
}
