import { StatusCompleteFilledSmall } from '@wix/wix-ui-icons-common';
import React, { VFC } from 'react';
import { Box, Image, Text, Card as WSRCard } from '@wix/design-system';
import { Addon } from '../../../types/addons';
import { useTranslation } from '../../useTranslation';
import styles from './addonsCard.scss';
import { SvgFallbackLogo } from '../../shared/panel/svg/svgFallbackLogo';

interface Props {
  addon: Addon;
  isInstalled: boolean;
  price: string;
}

export const Card: VFC<Props> = ({ addon, isInstalled, price }) => {
  const { name, iconUrl, description } = addon;
  const [t] = useTranslation();
  const footer = isInstalled ? (
    <Text
      size="tiny"
      skin="success"
      className={styles.labelInstalled}
      dataHook="addonsMarket.isInstalled"
    >
      <StatusCompleteFilledSmall />
      {t('PLATFORM_addons_panel_addon_card_installed_label')}
    </Text>
  ) : (
    <Text size="tiny" className={styles.labelPrice}>
      {price}
    </Text>
  );
  const logo = iconUrl ? (
    <Image
      alt={`${name} addon icon`}
      src={iconUrl}
      borderRadius={6}
      width={36}
      height={36}
      showBorder
      transparent
    />
  ) : (
    <SvgFallbackLogo />
  );
  return (
    <WSRCard className={styles.wrapper}>
      <Box
        padding="SP3"
        direction="vertical"
        height="198px"
        className={styles.root}
      >
        <Box marginBottom="SP2">{logo}</Box>
        <Box direction="vertical" marginBottom="SP1">
          <Box marginBottom="2px">
            <Text weight="bold" size="small" ellipsis maxLines={2}>
              {name}
            </Text>
          </Box>
          <Box>
            <Text size="tiny">
              {t('PLATFORM_addons_panel_addon_card_dev_label', {
                company_name: 'Wix',
              })}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text size="tiny" ellipsis maxLines={2}>
            {description}
          </Text>
        </Box>
        <Box flexGrow={1} />
        <Box alignItems="center">{footer}</Box>
      </Box>
    </WSRCard>
  );
};
