import { AddonsMarketProps } from '@wix/editor-platform-host-integration-apis';
import React, {
  FC,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { getAddons } from '../../../apis/services/addonsService';
import { Addon } from './types';
import { getQueryParams } from '../../../utils';

interface State {
  addons: Addon[];
  error: Error | null;
  state: 'idle' | 'loading' | 'loaded' | 'error';
}

type AddonsContextProviderProps = Pick<
  AddonsMarketProps,
  | 'handleInstall'
  | 'handleUninstall'
  | 'openAddonPanel'
  | 'closeAddonPanel'
  | 'isInstalled'
> & {
  children?: React.ReactNode;
};

interface AddonsContextProps extends AddonsContextProviderProps {
  state: State;
  fetchAddons: () => Promise<void>;
  isInstallationInProgress: boolean;
}

const initialState: State = {
  addons: [],
  error: null,
  state: 'idle',
};

export const AddonsContext = React.createContext<AddonsContextProps>({
  state: initialState,
  openAddonPanel: async () => {},
  closeAddonPanel: async () => {},
  isInstalled: () => false,
  fetchAddons: async () => {},
  handleInstall: async () => {},
  handleUninstall: async () => {},
  isInstallationInProgress: false,
});

export const AddonsContextProvider: FC<AddonsContextProviderProps> = ({
  children,
  openAddonPanel,
  closeAddonPanel,
  isInstalled,
  handleInstall: baseHandleInstall,
  handleUninstall,
}) => {
  const [isInstallationInProgress, setIsInstallationInProgress] =
    useState(false);

  const [state, setState] = useReducer<Reducer<State, Partial<State>>>(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initialState,
  );

  const fetchAddons = useCallback(async () => {
    const searchParams = new URLSearchParams();
    const queryParams = getQueryParams();
    if (queryParams.includeAddons) {
      searchParams.append('includeAddons', queryParams.includeAddons);
    }
    setState({ state: 'loading' });
    try {
      const addons = (await getAddons(searchParams)).map((addon) => ({
        ...addon,
        isInstalled: isInstalled(addon.appDefinitionId),
      }));
      setState({ addons, state: 'loaded' });
    } catch (err) {
      const error =
        err instanceof Error ? err : new Error('Could not load addons');
      setState({
        addons: [],
        error,
        state: 'error',
      });
    }
  }, [setState, isInstalled]);

  const handleInstall = useCallback(
    async (appDefinitionId: string) => {
      setIsInstallationInProgress(true);
      await baseHandleInstall(appDefinitionId);
      setIsInstallationInProgress(false);
    },
    [baseHandleInstall, setIsInstallationInProgress],
  );

  useEffect(() => {
    if (state.state === 'idle') {
      fetchAddons();
    }
  }, [fetchAddons, state.state]);

  return (
    <AddonsContext.Provider
      value={{
        state,
        openAddonPanel,
        closeAddonPanel,
        isInstalled,
        fetchAddons,
        handleInstall,
        handleUninstall,
        isInstallationInProgress,
      }}
    >
      {children}
    </AddonsContext.Provider>
  );
};
