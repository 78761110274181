import { HttpClient } from '@wix/http-client';
import { Addon } from '../../types/addons';

const httpClient = new HttpClient({
  baseURL: '/_serverless/editor-platform-addons/',
});

export const getAddons = async (
  searchParams?: URLSearchParams,
): Promise<Addon[]> => {
  const query = searchParams ? `?${searchParams.toString()}` : '';
  const response = await httpClient.get<Addon[]>(`/${query}`);
  return response.data;
};
